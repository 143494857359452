<template>
  <v-container fluid>
    <v-sheet
      elevation="1"
      class="pa-4"
    >
      <v-card
        elevation="2"
        outlined
        tile
        v-if="reportParameter.includes('fromdate') || reportParameter.includes('todate')"
      >
        <v-row
          dense
          class="pa-5"
        >
          <v-col cols="4">
            <h4>Tanggal</h4>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col
                cols="auto"
                v-if="reportParameter.includes('fromdate')"
              >
                <v-menu
                  ref="menuFromDate"
                  v-model="menuFromDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      hide-details
                      outlined
                      class="caption"
                      :placeholder="$_strings.common.FROM_DATE"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :value="dayjs(fromdate).format('DD/MM/YYYY')"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fromdate"
                    no-title
                    :max="dayjs(todate).format('YYYY-MM-DD')"
                    scrollable
                    @input="menuFromDate = false"
                    locale="id"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                class="d-none d-sm-flex align-center px-0"
                cols="12"
                sm="auto"
                v-if="reportParameter.includes('fromdate')"
              >-</v-col>
              <v-col
                v-if="reportParameter.includes('todate')"
                cols="auto"
              >
                <v-menu
                  ref="menuToDate"
                  v-model="menuToDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      hide-details
                      outlined
                      :value="dayjs(todate).format('DD/MM/YYYY')"
                      class="caption"
                      :placeholder="$_strings.common.TO_DATE"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="todate"
                    no-title
                    :min="reportParameter.includes('fromdate') ? dayjs(fromdate).format('YYYY-MM-DD') : null"
                    @input="menuToDate = false"
                    locale="id"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <v-card
        elevation="2"
        outlined
        tile
        class="mt-5"
        v-if="reportParameter.includes('isgroup')"
      >
        <v-row
          dense
          class="pa-5"
        >
          <v-col cols="4">
            <v-checkbox
              v-model="isEnabledTypeCustomer"
              hide-details
              class="shrink mr-2 mt-0"
              :label="$_strings.report.checkboxTypeCustomer"
            ></v-checkbox>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              v-model="isGroup"
              :items="itemsTypeCustomer"
              dense
              outlined
              clearable
              class="caption"
              :placeholder="$_strings.report.searchTypeCustomer"
              :disabled="!isEnabledTypeCustomer"
              :filled="!isEnabledTypeCustomer"
            />
          </v-col>
        </v-row>
      </v-card>
      <v-card
        elevation="2"
        outlined
        tile
        class="mt-5"
        v-if="reportParameter.includes('customerid')"
      >
        <v-row
          dense
          class="pa-5"
        >
          <v-col cols="4">
            <v-checkbox
              v-model="isEnabledCustomer"
              hide-details
              class="shrink mr-2 mt-0"
              :label="$_strings.report.checkboxCustomer"
            ></v-checkbox>
          </v-col>
          <v-col cols="12">
            <common-auto-complete-items
              type="masterShipper"
              searchName="companyName"
              item-text="companyName"
              item-value="id"
              v-model="customerid"
              dense
              outlined
              clearable
              class="caption"
              :placeholder="$_strings.report.searchCustomer"
              @updateItems="updateCustomerList"
              @change="setQueryParams('customerid')"
              :disabled="!isEnabledCustomer"
              :filled="!isEnabledCustomer"
              :filter="filterCustomerList"
              :items="itemsCustomer"
              :optional="{
                customerType: isGroup
              }"
            />
          </v-col>
        </v-row>
      </v-card>
      <v-card
        elevation="2"
        outlined
        tile
        class="mt-5"
        v-if="reportParameter.includes('transporttypeid')"
      >
        <v-row
          dense
          class="pa-5"
        >
          <v-col cols="4">
            <v-checkbox
              v-model="isEnabledTransportTypes"
              hide-details
              class="shrink mr-2 mt-0"
              :label="$_strings.report.checkboxTypeTransport"
            ></v-checkbox>
          </v-col>
          <v-col cols="12">
            <common-auto-complete-items
              type="transportTypes"
              searchName="name"
              item-value="id"
              item-text="name"
              v-model="transporttypeid"
              dense
              outlined
              clearable
              class="caption"
              :placeholder="$_strings.report.searchTypeKendaraan"
              @updateItems="updateTransportTypesList"
              @change="setQueryParams('transporttypeid')"
              :disabled="!isEnabledTransportTypes"
              :filled="!isEnabledTransportTypes"
              :filter="filterTransportTypesList"
              :items="itemsTransportTypes"
            />
          </v-col>
        </v-row>
      </v-card>
      <v-card
        elevation="2"
        outlined
        tile
        class="mt-5"
        v-if="reportParameter.includes('transporterid')"
      >
        <v-row
          dense
          class="pa-5"
        >
          <v-col cols="4">
            <v-checkbox
              v-model="isEnabledTransporter"
              hide-details
              class="shrink mr-2 mt-0"
              :label="$_strings.report.checkboxTransporter"
            ></v-checkbox>
          </v-col>
          <v-col cols="12">
            <common-auto-complete-items
              type="masterPartner"
              searchName="companyName"
              item-text="companyName"
              item-value="id"
              v-model="transporterid"
              dense
              outlined
              clearable
              class="caption"
              :placeholder="$_strings.report.searchTransporter"
              @updateItems="updateTransporterList"
              @change="setQueryParams('transporterid')"
              :disabled="!isEnabledTransporter"
              :filled="!isEnabledTransporter"
              :filter="filterTransporterList"
              :items="itemsTransporter"
            />
          </v-col>
        </v-row>
      </v-card>
      <v-card
        v-if="reportParameter.includes('typecust')"
        elevation="2"
        outlined
        tile
        class="mt-5"
      >
        <v-row
          dense
          class="pa-5"
        >
          <v-col cols="4">
            <v-checkbox
              v-model="isEnabledTypeCompany"
              hide-details
              class="shrink mr-2 mt-0"
              :label="$_strings.report.checkboxTypeCompany"
            ></v-checkbox>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              :items="itemsTypeCompany"
              v-model="typecust"
              dense
              outlined
              clearable
              class="caption"
              @change="setQueryParams('typecust')"
              :placeholder="$_strings.report.searchTypeCompany"
              :disabled="!isEnabledTypeCompany"
              :filled="!isEnabledTypeCompany"
            />
          </v-col>
        </v-row>
      </v-card>
      <v-card
        v-if="reportParameter.includes('companyid')"
        elevation="2"
        outlined
        tile
        class="mt-5"
      >
        <v-row
          dense
          class="pa-5"
        >
          <v-col cols="4">
            <v-checkbox
              v-model="isEnabledCompany"
              hide-details
              class="shrink mr-2 mt-0"
              :label="$_strings.report.checkboxCompany"
            ></v-checkbox>
          </v-col>
          <v-col cols="12">
            <common-auto-complete-items
              :type="typecust === 'SHIPPER' ? 'masterShipper': typecust === 'TRANSPORTER' ? 'masterPartner': 'masterCompany'"
              searchName="companyName"
              item-text="companyName"
              item-value="id"
              v-model="companyid"
              dense
              outlined
              clearable
              class="caption"
              :placeholder="$_strings.report.searchCompany"
              @updateItems="updateCompanyList"
              @change="setQueryParams('companyid')"
              :disabled="!isEnabledCompany"
              :filled="!isEnabledCompany"
              :filter="filterCompanyList"
              :items="itemsCompany"
            />
          </v-col>
        </v-row>
      </v-card>
      <v-card
        v-if="reportParameter.includes('servicetype')"
        elevation="2"
        outlined
        tile
        class="mt-5"
      >
        <v-row
          dense
          class="pa-5"
        >
          <v-col cols="4">
            <v-checkbox
              v-model="isEnabledServiceType"
              hide-details
              class="shrink mr-2 mt-0"
              :label="$_strings.report.checkboxServiceType"
            ></v-checkbox>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              :items="itemsServiceType"
              v-model="servicetype"
              dense
              outlined
              clearable
              class="caption"
              @change="setQueryParams('servicetype')"
              :placeholder="$_strings.report.searchServiceType"
              :disabled="!isEnabledServiceType"
              :filled="!isEnabledServiceType"
            />
          </v-col>
        </v-row>
      </v-card>
      <v-row align="center" justify="center" class="pa-5">
        <v-col cols="1">
          <v-btn color="primary" @click="preview" small>Tampilkan</v-btn>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import axios from 'axios';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  name: 'report-filter',
  created() {
    source = CancelToken.source();
  },
  beforeDestroy() {
    source.cancel('CANCELED_BY_THE_USER');
  },
  mounted() {
    const {
      isEnabledTransporter,
      isEnabledTransportTypes,
      isEnabledCustomer,
      isEnabledCompany,
      isgroup,
      reportParameter,
    } = this.$route.query;
    if (reportParameter) {
      this.reportParameter = reportParameter.split('|').toString().replace(/\s/g, '').split(',')
        .map((reportParam) => reportParam);
      const tempQuery = {};
      this.reportParameter.forEach((param) => {
        if (!this.$route.query[param]) tempQuery[param] = this[param];
      });
      if (JSON.stringify(this.$route.query) !== JSON.stringify({ ...this.$route.query, ...tempQuery })) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            ...tempQuery,
          },
        });
      }
    }
    if (isEnabledCustomer && this.customerid) {
      this.itemsCustomer = [{
        id: this.customerid,
        companyName: this.customername,
      }];
    }
    if (isEnabledTransporter && this.transporterid) {
      this.itemsTransporter = [{
        id: this.transporterid,
        companyName: this.transportername,
      }];
    }
    if (isEnabledTransportTypes && this.transporttypeid) {
      this.itemsTransportTypes = [{
        id: this.transporttypeid,
        name: this.transporttypesname,
      }];
    }
    if (isgroup !== 'true' && isgroup !== 'false') {
      this.isgroup = 'null';
    }
    if (isEnabledCompany && this.companyid) {
      this.itemsCompany = [{
        id: +this.companyid,
        companyName: this.companyname,
      }];
    }
  },
  data() {
    const { fromdate, todate } = this.$route.query;
    return {
      menuFromDate: false,
      menuToDate: false,
      fromdate: fromdate || dayjs().subtract('30', 'days').format('YYYY-MM-DD'),
      todate: todate || dayjs().format('YYYY-MM-DD'),
      reportType: {},
      reportParameter: [],
      isGroup: this.$route.query.isGroup || null,
      isgroup: 'null',
      servicetype: this.$route.query.servicetype || '',
      typecust: this.$route.query.typecust || '',
      transporttypeid: +this.$route.query.transporttypeid || '',
      transporttypesname: this.$route.query.transporttypesname || '',
      transportername: this.$route.query.transportername || '',
      transporterid: +this.$route.query.transporterid || null,
      customername: this.$route.query.customername || '',
      customerid: +this.$route.query.customerid || null,
      companyname: this.$route.query.companyname || '',
      companyid: +this.$route.query.companyid || null,
      isEnabledTypeCustomer: this.$route.query.isEnabledTypeCustomer === 'true',
      isEnabledTransportTypes: this.$route.query.isEnabledTransportTypes === 'true',
      isEnabledTransporter: this.$route.query.isEnabledTransporter === 'true',
      isEnabledCustomer: this.$route.query.isEnabledCustomer === 'true',
      isEnabledTypeCompany: this.$route.query.isEnabledTypeCompany === 'true',
      isEnabledCompany: this.$route.query.isEnabledCompany === 'true',
      isEnabledServiceType: this.$route.query.isEnabledServiceType === 'true',
      itemsServiceType: [
        {
          text: 'FCL',
          value: 'FCL',
        },
        {
          text: 'LCL',
          value: 'LCL',
        },
      ],
      itemsTypeCompany: [
        {
          text: 'Shipper',
          value: 'SHIPPER',
        },
        {
          text: 'Transporter',
          value: 'TRANSPORTER',
        },
      ],
      itemsTypeCustomer: [
        {
          text: 'External',
          value: 'External',
        },
        {
          text: 'Internal',
          value: 'Internal',
        },
      ],
      itemsCompany: [],
      itemsTransportTypes: [],
      itemsTransporter: [],
      itemsCustomer: [],
      filterCustomerList: {
        name: '',
        page: 0,
        size: 25,
        totalData: 0,
      },
      filterTransporterList: {
        name: '',
        page: 0,
        size: 25,
        totalData: 0,
      },
      filterTransportTypesList: {
        name: '',
        page: 0,
        size: 25,
        totalData: 0,
      },
      filterCompanyList: {
        name: '',
        page: 0,
        size: 25,
        totalData: 0,
      },
    };
  },
  watch: {
    fromdate() {
      this.setQueryParams('fromdate');
    },
    todate() {
      this.setQueryParams('todate');
    },
    isGroup(newVal) {
      if (newVal) {
        const val = newVal === 'Internal' ? 'true' : 'false';
        this.isgroup = val;
        this.setQueryParams('isgroup');
      } else {
        this.setNullIsgroup();
      }
      this.setQueryParams('isGroup');
      this.resetCustomerValueAndItems();
    },
    typecust() {
      this.itemsCompany = [];
      this.companyid = null;
      this.companyname = '';
      this.filterCompanyList = {
        name: '',
        page: 0,
        size: 25,
        totalData: 0,
      };
    },
    isEnabledTransporter(newVal) {
      if (!newVal) {
        this.transporterid = null;
        this.transportername = '';
        this.setQueryParams('transporterid');
        this.setQueryParams('transportername');
      }
      this.setQueryParams('isEnabledTransporter');
    },
    isEnabledTypeCustomer(newVal) {
      if (!newVal) {
        this.isGroup = null;
        this.resetCustomerValueAndItems();
        this.setNullIsgroup();
      }
      this.setQueryParams('isEnabledTypeCustomer');
    },
    isEnabledCustomer(newVal) {
      if (!newVal) {
        this.customerid = null;
        this.customername = '';
        this.setQueryParams('customerid');
        this.setQueryParams('customername');
      }
      this.setQueryParams('isEnabledCustomer');
    },
    isEnabledTransportTypes(newVal) {
      if (!newVal) {
        this.transporttypeid = null;
        this.transporttypesname = '';
        this.setQueryParams('transporttypeid');
        this.setQueryParams('transporttypesname');
      }
      this.setQueryParams('isEnabledTransportTypes');
    },
    isEnabledTypeCompany(newVal) {
      if (!newVal) {
        this.itemsCompany = [];
        this.typecust = '';
        this.companyid = null;
        this.companyname = '';
        this.setQueryParams('typecust');
        this.setQueryParams('companyid');
        this.setQueryParams('companyname');
      }
      this.setQueryParams('isEnabledTypeCompany');
    },
    isEnabledCompany(newVal) {
      if (!newVal) {
        this.companyid = null;
        this.companyname = '';
        this.setQueryParams('companyid');
        this.setQueryParams('companyname');
      }
      this.setQueryParams('isEnabledCompany');
    },
    isEnabledServiceType(newVal) {
      if (!newVal) {
        this.servicetype = '';
        this.setQueryParams('servicetype');
      }
      this.setQueryParams('isEnabledServiceType');
    },
    transporterid(newVal) {
      if (newVal) {
        this.transportername = this.itemsTransporter.find((transporter) => transporter.id === newVal).companyName;
      }
      this.setQueryParams('transportername');
    },
    customerid(newVal) {
      if (newVal) {
        this.customername = this.itemsCustomer.find((customer) => customer.id === newVal).companyName;
      }
      this.setQueryParams('customername');
    },
    transporttypeid(newVal) {
      if (newVal) {
        this.transporttypesname = this.itemsTransportTypes.find((types) => types.id === newVal).name;
      }
      this.setQueryParams('transporttypesname');
    },
    companyid(newVal) {
      if (newVal) {
        this.companyname = this.itemsCompany.find((comp) => comp.id === newVal).companyName;
      }
      this.setQueryParams('companyname');
    },
  },
  methods: {
    dayjs,
    setNullIsgroup() {
      this.isgroup = 'null';
      if (this.$route.query.isgroup !== 'null') {
        this.isgroup = 'null';
        this.$router.replace({
          query: {
            ...this.$route.query,
            isgroup: 'null',
          },
        });
      }
    },
    resetCustomerValueAndItems() {
      this.customerid = null;
      this.customername = '';
      this.setQueryParams('customerid');
      this.setQueryParams('customername');
      //  RESET VALUE CUSTOMER ITEMS
      this.itemsCustomer = [];
      this.filterCustomerList = {
        name: '',
        page: 0,
        size: 25,
        totalData: 0,
      };
    },
    updateCompanyList(items) {
      this.itemsCompany = [...this.itemsCompany, ...items.map((i) => ({
        id: i.id,
        companyName: i.companyName || i.name,
      }))];
    },
    updateCustomerList(items) {
      this.itemsCustomer = [...this.itemsCustomer, ...items];
    },
    updateTransporterList(items) {
      this.itemsTransporter = [...this.itemsTransporter, ...items];
    },
    updateTransportTypesList(items) {
      this.itemsTransportTypes = [...this.itemsTransportTypes, ...items.map((item) => ({
        id: item.id,
        name: item.name,
      }))];
    },
    setQueryParams(newVal) {
      if (String(this.$route.query[newVal]) !== String(this[newVal])) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            [newVal]: this[newVal],
          },
        });
      }
    },
    preview() {
      this.$router.push({
        name: 'report-detail',
        query: {
          ...this.$route.query,
        },
      });
    },
  },
};

</script>
